.two{
    margin-top: 0;
    width: 100vw;
    height: 100vh;
    background-image:url('../../Pic/darkBlue2.jpg');

    /* background-image:url('../../Pic/darkBlue1.jpg');
    transform:  translateX(180deg) translateY(0) rotate(180deg);
    transform: translateY(90deg); */

    background-color: #cccccc; 
    /* height: 892px;  */
    background-position: center; 
    /* background-position: right; */
    background-repeat: no-repeat; 
    background-size: cover; 

    position: relative;

    --colorYellow: rgba(220, 189, 90, 0.7);
}

.boxPage2 {
    position: absolute;
    display: grid;
    align-content: center;
    justify-items: center;
    /* top:130%; */
    /* top: 100%; */
    left: 50%;
    /* transform: translate3d(-50%,-30%, 0); */
    transform: translate3d(-50%, 70px , 0);
    width: 60vw;
    /* background-color: aqua; */
    /* background-color: cadetblue; */
}

.smallBox1 {
    position: relative;
    display: grid;
    align-content: center;
    justify-items: center;
    width: 400px;
    height: 50px;
    background-color: rgba(8,46,108,0.85);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    color: #faf7f7;
}

.smallBox {
    position: relative;
    display: grid;
    align-content: center;
    justify-items: center;
    width: 200px;
    height: 50px;
    color: #faf7f7;
    background-color: rgba(8,46,108,0.7);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 5px;
    left: 50%;
    transform: translate3d(-50%,0, 0);
}

.en > .smallBox {
  /* position: relative;
  display: grid;
  align-content: center;
  justify-items: center; */
  /* background-color: aqua; */
  /* direction: rtl; */
  left:-50%;
  transform: translate3d(50%,0, 0);
}

.box {
    position: relative;
    /* display: flex; */
    /* flex-direction:column; */
    display: grid;
    align-content: center;
    justify-items: center;
    /* align-items: center;
    justify-content: center; */
    overflow: scroll;
    /* background-color: rgba(220, 189, 90, 0.7); */
    background-color: var(--colorYellow);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    width: 100%;
    height: 200px;
    text-align: center;
    color: #020202;
    font-size: 1em;
    margin-top: auto;

    /* height: 100px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse; */

    /* overflow: auto; */
    /* display: flex; */
    /* flex-direction:column; */
}

.allText {
  padding: 10px 20px;
}

.subject{
    font-weight: bold;
    padding-bottom: 10px;
    /* padding-top: 40px; */
}

.subject1{
  padding-bottom: 5px;
}
.subject2{
  font-style: italic;
}

.university{
    color: rgb(8,46,108);
    /* font: 0.95em sans-serif; */
    font-weight: bold;
    padding-bottom: 15px;
}

.prise{
    font-style: italic;
    color: rgb(8,46,108);
    padding-bottom: 15px;
}

.project{
    font-style: italic;
    padding: 0 50px;
    /* color: rgb(8,46,108); */
}

/* @media screen and (max-width: 720px){
    .boxPage2 {
        width: 80vw;
        transition: all 1s;
    }
  }
  @media screen and (min-width: 720px){
    .boxPage2{transition: all 1s;}
  } */

  @media screen and (max-width: 720px){
    .smallBox1 {
      width: 300px;
      transition: all 1s;
    }
    .boxPage2 {
      width: 70vw;
      transition: all 1s;
    }
  }
  @media screen and (min-width: 720px){
    .smallBox1 {transition: all 1s;}
    .boxPage2 {transition: all 1s;}
  }

  @media screen and (max-height: 700px){
    .box{
      height: 100px;
      padding: 15px 0;
      align-content: flex-start;
      transition: all 1s;
      /* background-color: #cccccc; */
    }
    #subject{
      padding-top: 15px;
    }
    #project {
      display: none;
    }
    #prise{
      display: none;
    }
  }
  @media screen and (min-height: 700px){
    .box {transition: all 1s;}
  }

  @media screen and (max-width: 450px){
    .smallBox1 {
      width: 250px;
      transition: all 1s;
    }
    .box{
      align-content: flex-start;
      transition: all 1s;
      /* background-color: aquamarine; */
      font-size: small;
    }
    #subject{
      padding-top: 15px;
    }
    #project {
      display: none;
    }
  }
  @media screen and (min-width: 450px){
    .smallBox1 {transition: all 1s;}
    .box {transition: all 1s;}
  }