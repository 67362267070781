.five {
  margin-top: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('../../Pic/darkBlue1.jpg');
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  --colorYellow: rgba(220, 189, 90, 0.7);
}

.boxPage5 {
  position: absolute;
  display: grid;
  align-content: center;
  justify-items: center;
  left: 50%;
  transform: translate3d(-50%, 70px, 0);
  width: 60vw;
  /* background-color: cadetblue; */
}

.fa {
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  width: 100%;
}

.en {
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  width: 100%;
}

.smallBoxExp {
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  width: 400px;
  height: 50px;
  background-color: rgba(8, 46, 108, 0.85);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  color: #faf7f7;
}

.boxExp {
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  /* text-align: right; */
  text-align: start;
  overflow: scroll;
  /* background-color: rgba(220, 189, 90, 0.7); */
  background-color: var(--colorYellow);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  margin-top: 30px;
  width: 100%;
  /* height: 100%; */

  /* align-content:first baseline;
  justify-items: right; */

  /* align-content: end;
    justify-items: right; */

  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.boxPicText5 {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  padding: 50px;
  /* text-align: end; */
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  /* background-color:darkseagreen; */
}

.TitelBP {
  position: relative;
  display: grid;
  align-items: end;
  padding-right: 10px;
  font-weight: bold;
  direction: rtl;
  /* background-color: blanchedalmond; */
  /* align-content: end; */

  /* display: flex;
    align-items: end; */
}
.en > .boxExp > .boxPicText5 > .TitelBP {
  direction: ltr;
}

.picIconBluePencil {
  z-index: 1;
  background-color: #faf7f7;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  height: 70px;
  width: 70px;
}

.en>.boxExp>.boxPicText5 {
  left: -50%;
  transform: translate3d(50%, 0, 0);
  direction: rtl;
}

.en>.boxExp>.boxPicText5>.TitelBP {
  padding-left: 10px;
}

.textBP1 {
  position: relative;
  display: grid;

  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 10px;

  left: 50%;
  transform: translate3d(-50%, 0, 0);

  direction: rtl;
  font-weight: bold;
  color: rgb(8, 46, 108);

  /* grid-template-columns: auto auto; */
  /* justify-items: right; */
  /* align-items: right;
    align-content: right;
    justify-content: right;
    justify-items: right; */
  /* text-align: end; */
  /* background-color: #ff0000; */
}

.italicBP {
  font-style: italic;
  padding-right: 54px;
}

.textBP2 {
  position: relative;
  display: grid;
  padding-right: 50px;
  padding-left: 50px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  direction: rtl;
  padding-bottom: 5px;
  /* background-color: antiquewhite; */
}

ul {
  list-style-type: square;
}

.textBP3 {
  position: relative;
  display: grid;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 5px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  direction: rtl;
  font-style: italic;
}

.boxLinkDownload {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  padding-left: 50px;
  padding-right: 50px;
  /* direction: rtl; */
  right: 50%;
  transform: translate3d(50%, 0, 0);
  padding-bottom: 50px;

  /* background-color: rgb(255, 127, 249); */
}

.LinkDownload {
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  width: 120px;
  font-style: normal;
  background-color: rgba(8, 46, 108, 0.7);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  padding: 10px;
  margin: 5px;
  font-size: small;
}

.LinkDownload a {
  text-decoration: none;
  /* color: rgb(8,46,108); */
  color: #faf7f7;
}

.en > .boxExp > .textBP1 {
  direction: ltr;
  left: -50%;
  transform: translate3d(50%, 0, 0);
}
.en > .boxExp > .textBP2 {
  direction: ltr;
  left: -50%;
  transform: translate3d(50%, 0, 0);
}
.en > .boxExp > .textBP3 {
  direction: ltr;
  left: -50%;
  transform: translate3d(50%, 0, 0);
}
.en > .boxExp > .boxLinkDownload {
  right: -50%;
  transform: translate3d(-50%, 0, 0);
}


@media screen and (max-width: 850px) {
  .boxPage5 {
    /* width: 70vw; */
    height: 80vh;
    align-content: flex-start;
    transition: all 1s;
  }
}

@media screen and (min-width: 850px) {
  .boxPage5 {
    transition: all 1s;
  }
}

@media screen and (max-width: 720px) {
  .smallBoxExp {
    width: 300px;
    transition: all 1s;
  }

  .boxPage5 {
    width: 70vw;
    transition: all 1s;
  }
}

@media screen and (min-width: 720px) {
  .smallBoxExp {
    transition: all 1s;
  }

  .boxPage5 {
    transition: all 1s;
  }
}



@media screen and (max-height: 800px) {
  .boxExp {
    /* height: 85%; */
    height: 60vh;
    align-content: flex-start;
    transition: all 1s;
  }
}

@media screen and (min-height: 800px) {
  .boxExp {
    transition: all 1s;
  }
}


@media screen and (max-width: 450px) {
  .smallBoxExp {
    width: 250px;
    transition: all 1s;
  }
  .boxExp{
    font-size: small;
  }
}

@media screen and (min-width: 450px) {
  .smallBoxExp {
    transition: all 1s;
  }
}

/* @media screen and (max-height: 720px){
    .boxExp {
        height: 70%;
        transition: all 1s;
    }
    .boxPicText5{
        padding-top: 100px;
        transition: all 1s;
    }
  }
  @media screen and (min-height: 720px){
    .boxExp {transition: all 1s;}
    .boxPicText5{transition: all 1s;}
  } */

/* @media screen and (max-height: 650px){
    .boxExp {
        height: 55%;
        transition: all 1s;
    }
    .boxPicText5{
        padding-top: 165px;
        transition: all 1s;
    }
  }
  @media screen and (min-height: 650px){
    .boxExp {transition: all 1s;}
    .boxPicText5{transition: all 1s;}
  } */

/* @media screen and (max-height: 590px){
    .boxExp {
        height: 55%;
        transition: all 1s;
    }
    .boxPicText5{
        padding-top: 165px;
        transition: all 1s;
    }
  }
  @media screen and (min-height: 590px){
    .boxExp {transition: all 1s;}
    .boxPicText5{transition: all 1s;}
  } */



/* #LinkDownload {
    font-style: normal;
    background-color: #faf7f7;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    padding: 8px;
}

#LinkDownload a {
    text-decoration: none;
    color: rgb(8,46,108);
} */

/* For mobile: */
/* @media screen and (max-width: 600px){
    .boxExp{
        width: 80vw;
        margin-left: 10vw;
        transition: all 4s;
    }
    #TitelBP {
        padding-right: 22vh;
        transition: all 4s;
    }
  } */
/* @media screen and (min-width: 600px){
    .boxExp {transition: all 4s;}
    #TitelBP {transition: all 4s;}
  } */

/* For mobile: */
/* @media screen and (max-width: 450px){
    #TitelBP {
        padding-right: 17vh;
        transition: all 4s;
    }
  }
  @media screen and (min-width: 450px){
    .boxExp {transition: all 4s;}
    #TitelBP {transition: all 4s;}
  } */