.three{
    margin-top: 0;
    width: 100vw;
    height: 100vh;
    background-image:url('../../Pic/darkBlue1.jpg');
    background-color: #cccccc; 
    /* height: 892px;  */
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    position: relative;
    --colorYellow: rgba(220, 189, 90, 0.7);
}

.boxPage3 {
    position: absolute;
    display: grid;
    align-content: center;
    justify-items: center;
    text-align: center;
    /* top: 200%; */
    left: 50%;
    transform: translate3d(-50%, 70px , 0);
    width: 60vw;
    /* background-color: aqua; */
    /* background-color: cadetblue; */
}

.smallBoxPaper {
    /* position: absolute;
    width: 30vw;
    height: 5vh;
    margin-left: 35vw;
    background-color: rgba(8,46,108,0.85);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px; */

    position: relative;
    display: grid;
    align-content: center;
    justify-items: center;
    width: 400px;
    height: 50px;
    background-color: rgba(8,46,108,0.85);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    color: #faf7f7;
    
}

.allBoxPapers{
    position: relative;
    display: grid;
    align-content: center;
    justify-items: center;
    overflow: scroll;
    margin-top: 30px;
    /* background-color: antiquewhite; */
    width: 60vw;
}

.boxPaper{
    position: relative;
    display: grid;
    align-content: center;
    justify-items: center;
    overflow: scroll;
    /* background-color: rgba(220, 189, 90, 0.7); */
    background-color: var(--colorYellow);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;

    width: 92%;
    /* width: 94%; */
    height: 80px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    /* background-color: rgba(246, 248, 250, 0.8); */
}
.right { left: 3%; }
.left  { right: 3%;}

.title {
    /* font-weight: bold; */
    padding: 5px;
}
.nameConf{
    /* font-style: italic; */
    font-size: small;
    color: rgb(8,46,108);
}
.date {
    font-style: italic;
}

@media screen and (max-width: 1145px){
    .boxPage3 {
      width: 70vw;
      transition: all 1s;
    }
    .allBoxPapers {
      width: 70vw;
      transition: all 1s;
    }
}
@media screen and (min-width: 1145px){
    .boxPage3 {transition: all 1s;}
    .allBoxPapers {transition: all 1s;}
}

@media screen and (max-width: 720px){
    .smallBoxPaper {
      width: 300px;
      transition: all 1s;
    }
    .boxPage3 {
      /* width: 70vw; */
      width: 80vw;
      transition: all 1s;
      /* background-color: hotpink; */
    }
    .allBoxPapers {
      /* width: 70vw; */
      width: 80vw;
      transition: all 1s;
    }
}
@media screen and (min-width: 720px){
    .smallBoxPaper {transition: all 1s;}
    .boxPage3 {transition: all 1s;}
    .allBoxPapers {transition: all 1s;}
}


@media screen and (max-height: 834px){
    /* .allBoxPapers{
        grid-template-columns: auto auto;
        background-color: antiquewhite;
    } */
    .boxPaper{
        align-content: flex-start;
        height: 9vh;
        transition: all 1s;
    }
    /* .right { left: 0; }
    .left  { right: 0;} */
}
@media screen and (min-height: 834px){
    .boxPaper {transition: all 1s;}
}

@media screen and (max-height: 700px){
    .boxPaper{
        align-content: flex-start;
        height: 8vh;
        font-size: small;
        transition: all 1s;
    }
    .title{
        padding-top: 15px;
        /* font-size: small; */
    }
    .nameConf{
        /* display: none; */
        padding: 15px;
        /* font-size: smaller; */
    }
}
@media screen and (min-height: 700px){
    .boxPaper {transition: all 1s;}
}

@media screen and (max-width: 450px){
    .smallBoxPaper {
      width: 250px;
      transition: all 1s;
    }
    .boxPaper{
        height: 8vh;
        transition: all 1s;
    }
    .allBoxPapers{
        font-size: small;
    }
    /* .box{
      align-content: flex-start;
      transition: all 1s;
    } */
    .title{
        padding-top: 15px;
    }
    .nameConf{
        /* display: none; */
        padding: 15px;
    }
  }
  @media screen and (min-width: 450px){
    .smallBoxPaper {transition: all 1s;}
    /* .box {transition: all 1s;} */
  }


/* 
.boxPaper {
    position: absolute;
    overflow: scroll;
    background-color: rgba(220, 189, 90, 0.7);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 70vw;
    height: 70vh;
    margin-left: 15vw;
    border-radius: 8px;
    padding: 20px 0 20px 0;
}

.topPaper1 {margin-top: 10vh;}
.topPaper2 {margin-top: 18vh;}

.smallBoxPaper p {
    text-align: center;
    color: #faf7f7;
    margin-top: auto;
    padding-top: 10px;
}


.boxPaper p {
    text-align: center;
    color: #020202;
    font: 1em sans-serif;
    margin-top: auto;
    padding: 1px 30px 1px 30px;
}

hr {
    width: 80%;
}

#title {
    padding-top: 15px;
    font-weight: bold;
}

#name {
    font: 1em sans-serif;
}

#nameConf{
    font-style: italic;
    color: rgb(8,46,108);
}

#date {
    font-style: italic;
}

#link{
    text-align: center;
}

a:link,
a:visited,
a:hover,
a:active{
    color: rgb(8,46,108);
} */


/* .foldedCorner {
    position: absolute;
    background-color: aqua;
    width: 100px;
    height: 100px;
    top: 210%;
    left: 6%;
}
.fold {
    position: relative;
    background-color: red;
    width: 90px;
    height: 30px;
    top: 5px;
    left: 5px;
}

.fold::before {
    content: "";
    position: absolute;
    border-width: 0 16px 16px 0;
    border-style: solid;
    border-color: aquamarine;
    display: block;
    width: 0;
    box-shadow: #cccccc;
} */