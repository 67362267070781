@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100..900&display=swap');


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', "Vazirmatn",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* font-family: "Vazirmatn", sans-serif; */
    /* font-optical-sizing: auto; */
    /* font-style: normal; */

    /* // <weight>: Use a value from 100 to 900 */
    /* font-weight: <weight>; */
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  