.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
.mainImg {
    /* background-image:url('Pic/sky.png'); */
    /* background-image:url('Pic/darkBlue5.jpg'); */
    width: 100vw;
    height: 100vh;
    position: fixed;

    /* The image used */
    /* background-image: url('Pic/darkBlue1.jpg'); */

    /* Used if the image is unavailable */
    /* background-color: #cccccc;  */

    /* You must set a specified height */
    /* height: 500px;  */

    /* Center the image */
    /* background-position: center;  */

    /* Do not repeat the image */
    /* background-repeat: no-repeat;  */

    /* Resize the background image to cover the entire container */
    /* background-size: cover;  */
}

/* .scroll-container {
    display: block;
    margin: 0 auto;
    text-align: center;
    overflow-y: scroll;
    scroll-behavior: smooth;
} */

.scroller {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-stop: always;
}

/* .scroller section {
  scroll-snap-align: start;
} */

.section {
  scroll-snap-align: start;
}

.circle {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: gold;
  box-shadow: 2px 1px 1px 0 rgb(71, 69, 69), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  position: fixed;
  z-index: 1;
  right: 30px;
}

.en > .circle {
  left: 30px;
}

.dot1 {
  /* margin-top:40vh; */
  top: 40%;
}
.dot2 {
  /* margin-top:45vh; */
  top: 45%;
}
.dot3 {
  /* margin-top:50vh; */
  top: 50%;
}
.dot4 {
  /* margin-top:55vh; */
  top: 55%;
}
.dot5 {
  /* margin-top:60vh; */
  top: 60%;
}
.dot6 {
  /* margin-top:65vh; */
  top: 65%;
}


.circle:hover:after {
  position: absolute;
  display: block;
  /* display: inline-block; */
  background-color: #cccccc;
  box-shadow: 2px 1px 1px 0 rgb(150, 147, 147), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100px;
  height: 20px;
  /* margin-left: -100px; */
  border-radius: 5px;
  text-align: center;
  /* font: 0.8em sans-serif; */
  font-size: small;
  align-content: center;
  justify-items: center;
  right: 22px;
}

.en > .circle:hover:after {
  /* margin-left: -100px; */
  left: 22px;
}

.fa > .dot1:hover:after {content: "صفحه اصلی";}
.fa > .dot2:hover:after {content: 'سوابق تحصیلی';}
.fa > .dot3:hover:after {content: 'مقالات';}
.fa > .dot4:hover:after {content: 'مهارت ها';}
.fa > .dot5:hover:after {content: 'تجارب';}
.fa > .dot6:hover:after {content: 'راه ارتباطی';} 

.en > .dot1:hover:after {content: "MAIN";}
.en > .dot2:hover:after {content: 'EDUCATION';}
.en > .dot3:hover:after {content: 'PUBLICATION';}
.en > .dot4:hover:after {content: 'SKILLS';}
.en > .dot5:hover:after {content: 'EXPERIENCE';}
.en > .dot6:hover:after {content: 'CONTACT';} 

/* *:lang(en) {background-color: aqua;} */


/* .dot1:active {background-color: aqua;} */
/* .dot2:active {background-color: aqua;} */

/* .dot1:hover:after {content: "صفحه اصلی";}
.dot2:hover:after {content: 'سوابق تحصیلی';}
.dot3:hover:after {content: 'مقالات';}
.dot4:hover:after {content: 'مهارت ها';}
.dot5:hover:after {content: 'تجارب';}
.dot6:hover:after {content: 'راه ارتباطی';} */

/* language: */
.lang {
  /* position: relative; */
  position: fixed;
  align-content: center;
  justify-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgba(220, 189, 90, 0.85);
  /* background-color: var(--colorYellow); */
  /* box-shadow: 2px 1px 1px 0 rgb(71, 69, 69), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: 1.5px 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  top: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 3;
}
.imgLang {
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  margin: auto;
}
.imgLang > img {
  /* height: 28px; */
  /* width: 30px; */
  width: 25px;
}
.langleg {
  /* position: relative; */
  position: fixed;
  display: grid;
  align-content: center;
  justify-items: center;
  width: 26px;
  /* height: 14px; */
  line-height: 13px;
  border-radius: 10%;
  color: aliceblue;
  background-color: rgba(8, 46, 108, 0.7);
  /* background-color: rgba(220, 189, 90, 0.85); */
  /* box-shadow: 2px 1px 1px 0 rgb(71, 69, 69), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: 1.5px 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  top: 41px;
  left: 12px;
  cursor: pointer;
  font-size: smaller;
  z-index: 3;
}

/* For mobile: */
@media screen and (max-width: 600px){
  .circle:hover:after {
    /* width: 15vw; */
    /* margin-left: -15vw; */
    transition: all 1s;
  }
}
@media screen and (min-width: 600px){
  .circle:hover:after{transition: all 1s;}
}
