.one {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('../../Pic/darkBlue1.jpg');
  position: relative;
  
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  --colorBlue: rgba(8, 46, 108, 0.85);
  --colorYellow: rgba(220, 189, 90, 0.85);
  --colorBoxShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
}

.boxAll {
  position: absolute;
  display: grid;
  align-content: center;
  justify-items: center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  /* background-color: cadetblue; */
}

.boxPicText {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;

  /* background-color: rgb(0, 47, 255); */
}

.picSara {
  position: relative;
  width: 160px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: var(--colorBoxShadow);
  border-radius: 8px;
}

.textPageOne {
  display: grid;
  align-content: center;
  justify-items: center;
  /* background-color: rgba(220, 189, 90, 0.85); */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  background-color: var(--colorYellow);
  box-shadow: var(--colorBoxShadow);
  border-radius: 8px;
  font-weight: 600;
  font-size: medium;
  direction: rtl;
  text-align: center;
  margin-top: auto;
  margin-right: 3px;
  margin-left: 3px;
  width: 260px;
  height: 45px;
}

/* Circles: */

.boxCircles {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  /* background-color: blueviolet; */
}

.circlePageOne {
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  border-radius: 50%;
  color: aliceblue;
  /* background-color: rgba(8, 46, 108, 0.85); */
  background-color: var(--colorBlue);
  /* box-shadow: 2px 1px 1px 0 rgb(71, 69, 69), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* --colorBoxShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* box-shadow: var(--colorBoxShadow); */
  
  box-shadow: 1.5px 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

  width: 140px;
  height: 140px;
  cursor: pointer;
}

.circlePageOne:hover {
  /* background-color: rgba(220, 189, 90, 0.85); */
  background-color: var(--colorYellow);
  color: black;
}

.fa > .boxPicText{
  direction: rtl;
}
.en > .boxPicText{
  direction: ltr;
}
.en > .boxCircles {
  /* old: */
  /* transform: translateX(0%) translateY(0%) rotate(0deg); */

  /* Az Bala: */
  transform: translateX(0%) translateY(0%) rotate(-180deg);

  /* Az Paeen: */
    /* transform: translateX(0%) translateY(0%) rotate(180deg); */
}
.en > .boxCircles > .circlePageOne {
  /* transform: translateX(0%) translateY(0%) rotate(0deg); */
  transform: translateX(0%) translateY(0%) rotate(-180deg);
  /* transform: translateX(0%) translateY(0%) rotate(180deg); */
}

@media screen and (max-width: 720px) {
  .boxAll {
    width: 70vw;
    height: 70vh;
    /* transition: all 3s; */
    /* background-color: aqua; */
  }
  .boxPicText {
    top: -140px;
    /* transition: all 3s; */
  }
  .picSara {
    width: 130px;
    /* transition: all 3s; */
  }
  .textPageOne {
    width: 220px;
    /* transition: all 3s; */
  }
  .boxCircles {
    width: 100%;
    height: 100%;
    top: -90px;
    transform: translateX(0%) translateY(0%) rotate(-90deg);
    /* transition: all 3s; */
  }
  .circlePageOne {
    border-radius: 10px;
    width: 400px;
    height: 50px;
    margin: 0 -171px;
    transform: translateX(0%) translateY(0%) rotate(90deg);
    /* transition: all 3s; */
  }

  .en > .boxCircles {
    transform: translateX(0%) translateY(0%) rotate(-90deg);
    /* transform: translateX(0%) translateY(0%) rotate(270deg); */
    /* transition: all 3s; */

  }
  .en > .boxCircles > .circlePageOne {
    transform: translateX(0%) translateY(0%) rotate(90deg);
    /* transform: translateX(0%) translateY(0%) rotate(-270deg); */
    /* transition: all 3s; */

  }
}
/* 
@media screen and (min-width: 720px) {
  .boxAll {transition: all 3s;}
  .boxPicText {transition: all 3s;}
  .picSara {transition: all 3s;}
  .textPageOne {transition: all 3s;}
  .boxCircles {transition: all 3s;}
  .circlePageOne {transition: all 3s;}
} */


@media screen and (max-width: 450px) {
  .picSara {
    width: 100px;
    /* transition: all 3s; */
  }
  .textPageOne {
    width: 180px;
    /* transition: all 3s; */
  }
  .circlePageOne {
    width: 300px;
    margin: 0 -123px;
    /* transition: all 3s; */
  }
}

/* @media screen and (min-width: 450px) {
  .picSara {transition: all 3s;}
  .textPageOne {transition: all 3s;}
  .circlePageOne {transition: all 3s;}
} */

/* ...................................................................... */
/* @keyframes example {
  from {background-color: red;}
  to {background-color: yellow;}
} */

/* div {
  width: 100px;
  height: 100px;
  background-color: red;
  animation-name: example;
  animation-duration: 4s;
} */
/* ...................................................................... */