.four{
    margin-top: 0;
    width: 100vw;
    height: 100vh;
    background-image:url('../../Pic/darkBlue2.jpg');
    background-color: #cccccc; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    position: relative;

    --colorYellow: rgba(220, 189, 90, 0.7);
    --colorBox: rgba(8,46,108,0.6);
    --colorWhite: rgba(255, 255, 255, 1);
}

.boxPage4{
    position: absolute;
    display: grid;
    align-content: center;
    justify-items: center;
    /* top: 300%; */
    left: 50%;
    transform: translate3d(-50%, 70px , 0);
    width: 60vw;
    /* background-color: cadetblue; */
}

.smallBoxSkill {
    position: relative;
    display: grid;
    align-content: center;
    justify-items: center;
    width: 400px;
    height: 50px;
    background-color: rgba(8,46,108,0.85);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    color: #faf7f7;
}

.boxSkill {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
  justify-items: center;
  overflow: scroll;
  /* background-color: rgba(220, 189, 90, 0.7); */
  background-color: var(--colorYellow);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  width: 100%;
  margin-top: 30px;
  /* padding-top: 20px;
  padding-bottom: 20px; */
  /* padding: 20px 0; */
  padding: 6vh 0;
  align-content: flex-start;
}

.boxs{
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  /* padding-bottom: 10px; */
  padding-bottom: 2.5vh;
  color: rgb(8,46,108);
  /* background-color: aqua; */
}

.textBoxs{
  position: relative;
  display: grid;
  align-content: left;
  justify-items: left;
  text-align: left;
  width: 25vw;
  padding-left: 6% ;
  /* background-color: rgb(30, 157, 112); */
}

.value {
  width: 90%;
  height: 30px;
  border: 1px solid;
  border-radius: 8px;
  margin-top: 4px;
  background: linear-gradient(to right, rgba(8,46,108,0.7) 0%, rgba(255, 255, 255, 0.7) 100%);
}

/* #DataMining{background: linear-gradient(to right, rgba(8,46,108,0.7) 80%, rgba(255, 255, 255, 1) 20%);}
#AndroidDevelopment{background: linear-gradient(to right, rgba(8,46,108,0.7) 80%, rgba(255, 255, 255, 1) 20%);}
#FrontEnd {background: linear-gradient(to right, rgba(8,46,108,0.7) 90%, rgba(255, 255, 255, 1) 10%);}
#CSS{background: linear-gradient(to right, rgba(8,46,108,0.7) 95%, rgba(255, 255, 255, 1) 5%);}
#JavaScript{background: linear-gradient(to right, rgba(8,46,108,0.7) 80%, rgba(255, 255, 255, 1) 20%);}
#ReactJs{background: linear-gradient(to right, rgba(8,46,108,0.7) 90%, rgba(255, 255, 255, 1) 10%);}
#Research{background: linear-gradient(to right, rgba(8,46,108,0.7) 100%, rgba(255, 255, 255, 1) 0%);}
#PersianLanguage{background: linear-gradient(to right, rgba(8,46,108,0.7) 100%, rgba(255, 255, 255, 1) 0%);}
#EnglishLanguage{background: linear-gradient(to right, rgba(8,46,108,0.7) 70%, rgba(255, 255, 255, 1) 30%);}
#GermanLanguage{background: linear-gradient(to right, rgba(8,46,108,0.7) 50%, rgba(255, 255, 255, 1) 50%);} */

/* 
#ReactJs{background: linear-gradient(to right, rgba(8,46,108,0.6) 90%, rgba(255, 255, 255, 1) 10%);}
#DataMining{background: linear-gradient(to right, rgba(8,46,108,0.6) 80%, rgba(255, 255, 255, 1) 20%);}
#AndroidDevelopment{background: linear-gradient(to right, rgba(8,46,108,0.6) 80%, rgba(255, 255, 255, 1) 20%);}
#JavaScript{background: linear-gradient(to right, rgba(8,46,108,0.6) 80%, rgba(255, 255, 255, 1) 20%);}
#FrontEnd {background: linear-gradient(to right, rgba(8,46,108,0.6) 90%, rgba(255, 255, 255, 1) 10%);}
#CSS{background: linear-gradient(to right, rgba(8,46,108,0.6) 95%, rgba(255, 255, 255, 1) 5%);}
#Research{background: linear-gradient(to right, rgba(8,46,108,0.6) 100%, rgba(255, 255, 255, 1) 0%);}
#PersianLanguage{background: linear-gradient(to right, rgba(8,46,108,0.6) 100%, rgba(255, 255, 255, 1) 0%);}
#EnglishLanguage{background: linear-gradient(to right, rgba(8,46,108,0.6) 70%, rgba(255, 255, 255, 1) 30%);}
#GermanLanguage{background: linear-gradient(to right, rgba(8,46,108,0.6) 50%, rgba(255, 255, 255, 1) 50%);} */

#ReactJs{background: linear-gradient(to right, var(--colorBox) 90%, var(--colorWhite) 10%);}
#DataMining{background: linear-gradient(to right, var(--colorBox) 80%, var(--colorWhite) 20%);}
#AndroidDevelopment{background: linear-gradient(to right, var(--colorBox) 80%, var(--colorWhite) 20%);}
#JavaScript{background: linear-gradient(to right, var(--colorBox) 80%, var(--colorWhite) 20%);}
#FrontEnd {background: linear-gradient(to right, var(--colorBox) 90%, var(--colorWhite) 10%);}
#CSS{background: linear-gradient(to right, var(--colorBox) 95%, var(--colorWhite) 5%);}
#Research{background: linear-gradient(to right, var(--colorBox) 100%, var(--colorWhite) 0%);}
#PersianLanguage{background: linear-gradient(to right, var(--colorBox) 100%, var(--colorWhite) 0%);}
#EnglishLanguage{background: linear-gradient(to right, var(--colorBox) 70%, var(--colorWhite) 30%);}
#GermanLanguage{background: linear-gradient(to right, var(--colorBox) 50%, var(--colorWhite) 50%);}


@media screen and (max-width: 720px){
  .smallBoxSkill {
    width: 300px;
    transition: all 1s;
  }
  .boxPage4{
    width: 70vw;
    transition: all 1s;
  }
}
@media screen and (min-width: 720px){
  .smallBoxSkill {transition: all 1s;}
  .boxPage4 {transition: all 1s;}
}

@media screen and (max-width: 900px){
  .boxSkill {
    grid-template-columns: auto;
    height: 60vh;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    transition: all 1s;
    /* background-color: green; */
  }
  .textBoxs{
    width: 50vw;
    padding-left: 6%;
    transition: all 1s;
  }
}
@media screen and (min-width: 900px){
  .boxSkill {transition: all 1s;}
  .textBoxs {transition: all 1s;}
}


@media screen and (max-height: 750px){
  .boxSkill {
    /* padding-top: 50%; */
    /* height: 30%; */
    height: 60vh ;
    transition: all 1s;
    /* background-color: blue; */
  }
}
@media screen and (min-height: 750px){
  .boxSkill {transition: all 1s;}
}

@media screen and (max-height: 700px){
  .boxSkill {
    /* padding-top: 40%; */
    /* height: 25%; */
    transition: all 1s;
    /* background-color: aquamarine; */
  }
}
@media screen and (min-height: 700px){
  .boxSkill {transition: all 1s;}
}

@media screen and (max-height: 650px){
  .boxSkill {
    /* padding-top: 60%; */
    /* height: 20%; */
    transition: all 1s;
    /* background-color: chartreuse; */
  }
}
@media screen and (min-height: 650px){
  .boxSkill {transition: all 1s;}
}

@media screen and (max-width: 450px){
  .smallBoxSkill {
    width: 250px;
    transition: all 1s;
  }
  .boxSkill{
    padding: 2vh 0;
    transition: all 1s;
  }
  .textBoxs{
    width: 60vw;
    padding-left: 12% ;
    transition: all 1s;
    /* background-color: rgb(30, 157, 112); */
  }
}
@media screen and (min-width: 450px){
  .smallBoxSkill {transition: all 1s;}
  .boxSkill {transition: all 1s;}
  .textBoxs {transition: all 1s;}
}

/* .RightSkill{
  position:relative;
  width: 50%;
  background-color: rgb(255, 0, 191);
  left: 25%;
} */

/* .LeftSkill{
  position: relative;
  width: 50%;
  background-color: rgb(242, 255, 0);
  right: 25%;
} */

/* .tableP4 {
    position: relative;
    display: grid;
    align-content: center;
    justify-items: center;
    overflow: scroll;
    background-color: rgba(220, 189, 90, 0.7);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    top: 50px;
  }

  tr {
    border: 1px solid;
    
  }

  th {
    border: 1px solid;
  }

  td {
    border: 1px solid;
    color: #089ef4;
    width: 400px;
  }

  #titleTP4{
    color: #000;
    
  }
  #blank-middel{
    height: 30px;
  } */

  /* #s1, #s01, #s001, #s0001, #s00001,
  #sk1, #sk01, #sk001, #sk0001, #sk00001 {
    width: 35vw;
    height: 3vh;
    border: 1px solid;
    color: rgb(8,46,108);
  } */

  /* #s2, #s02, #s002, #s0002, #s00002,
  #sk2, #sk02, #sk002, #sk0002, #sk00002 {
    width: 10vw;
    height: 3vh;
    border: none;
  } */

  /* #s3, #s03, #s003, #s0003, #s00003,
  #sk3, #sk03, #sk003, #sk0003, #sk00003 {
    width: 35vw;
    height: 3vh;
    border: 1px solid;
    color: rgb(8,46,108);
  } */

  /* #sk1, #sk01, #sk001, #sk0001, #sk00001,
  #sk2, #sk02, #sk002, #sk0002, #sk00002,
  #sk3, #sk03, #sk003, #sk0003, #sk00003 {
    color: rgb(8,46,108);
  } */

  /* #s1, #s01, #s001, #s0001, #s00001,
  #s2, #s02, #s002, #s0002, #s00002,
  #s3, #s03, #s003, #s0003, #s00003 {
    border: none;
    text-align: left;
    height: 3vh;
    padding-top: 12px;
  } */

  
/* 
  #sk1 {background: linear-gradient(to right, rgba(8,46,108,0.7) 80%, rgba(255, 255, 255, 0.7) 20%);}
  #sk01 {background: linear-gradient(to right, rgba(8,46,108,0.7) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk001 {background: linear-gradient(to right, rgba(8,46,108,0.7) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk0001 {background: linear-gradient(to right, rgba(8,46,108,0.7) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk00001 {background: linear-gradient(to right, rgba(8,46,108,0.7) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk3 {background: linear-gradient(to right, rgba(8,46,108,0.7) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk03 {background: linear-gradient(to right, rgba(8,46,108,0.7) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk003 {background: linear-gradient(to right, rgba(8,46,108,0.7) 100%, rgba(255, 255, 255, 0.7) 0%);}
  #sk0003 {background: linear-gradient(to right, rgba(8,46,108,0.7) 70%, rgba(255, 255, 255, 0.7) 30%);}
  #sk00003 {background: linear-gradient(to right, rgba(8,46,108,0.7) 50%, rgba(255, 255, 255, 0.7) 50%);} */

  /* #sk1 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 80%, rgba(255, 255, 255, 0.7) 20%);}
  #sk01 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk001 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk0001 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk00001 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk3 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk03 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 90%, rgba(255, 255, 255, 0.7) 10%);}
  #sk003 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 100%, rgba(255, 255, 255, 0.7) 0%);}
  #sk0003 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 70%, rgba(255, 255, 255, 0.7) 30%);}
  #sk00003 {background: linear-gradient(to right, rgba(220, 189, 90, 0.85) 50%, rgba(255, 255, 255, 0.7) 50%);} */


/* .progress:after {
    width: 0;
    transition: all 2s;
    -webkit-transition: all 2s;
}
.progress:hover:after {
    width: 50%;
} */

/* .progress:after {
  width: 50%; 
  -webkit-animation: filler 2s ease-in-out;
  -moz-animation: filler 2s ease-in-out;
  animation: filler 2s ease-in-out;
} */

/* @-webkit-keyframes filler {
  0% {
      width: 0;
  }
} */

/* #sk1:after {
  width: 0;
  transition: all 2s;
  -webkit-transition: all 2s;



  width: 80%;
  -webkit-animation: filler 2s ease-in-out;
  -moz-animation: filler 2s ease-in-out;
  animation: filler 2s ease-in-out;
} */

/* #sk1:hover:after {
  width: 50%;
}  */
