.six{
    margin-top: 0;
    width: 100vw;
    height: 100vh;
    background-image:url('../../Pic/darkBlue2.jpg');
    background-color: #cccccc; 
    /* height: 892px;  */
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    position: relative;

    --colorYellow: rgba(220, 189, 90, 0.7);
}

.boxPage6{
  position: absolute;
  display: grid;
  align-content: center;
  justify-items: center;
  /* top: 500%; */
  left: 50%;
  transform: translate3d(-50%, 70px , 0);
  width: 60vw;
  /* background-color: cadetblue; */
}

.smallBoxCallMe {
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  width: 400px;
  height: 50px;
  background-color: rgba(8,46,108,0.85);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  color: #faf7f7;
}

.boxAlert{
  position: relative;
  /* display: grid; */
  align-content: center;
  justify-items: center;
  text-align: center;
  border: 1px solid;
  color: rgba(8,46,108,0.85);
  /* background-color: rgba(220, 189, 90, 0.7); */
  background-color: var(--colorYellow);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  width: 70%;
  height: 50px;
  margin-top: 10px;
  direction: rtl;
  display: none;
  /* transition: all 1s; */
}

.boxForm{
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  overflow: scroll;
  /* background-color: rgba(220, 189, 90, 0.7); */
  background-color: var(--colorYellow);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  width: 100%;
  margin-top: 30px;
  direction: rtl;
  transition: all 1s;
}

.en > .boxForm {
  direction: ltr;
}

.boxFormInside{
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
  overflow: scroll;
  margin: 30px;

  /* background-color: aqua; */
}

.NameEmailP6{
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
  justify-items: center;
  overflow: scroll;
  /* background-color: rgb(19, 106, 106); */
}

.NameP6 input, .EmailP6 input {
  width: 25vw;
  padding: 10px;
  margin: 2px;
  /* font: 0.8em sans-serif; */
  border: 1px solid rgba(8,46,108,0.85);
  border-radius: 5px;
}

.TitleP6 input{
  width: calc(100% - 25px);
  padding: 10px;
  margin: 2px;
  /* font: 0.8em sans-serif; */
  border: 1px solid rgba(8,46,108,0.85);
  border-radius: 5px;
}

.TextP6 textarea{
  /* width: 50vw; */
  width: calc(100% - 25px);
  padding: 10px;
  margin: 2px;
  /* font: 0.8em sans-serif; */
  border: 1px solid rgba(8,46,108,0.85);
  border-radius: 5px;
  overflow: scroll;
  resize: vertical;
  height: 30vh;
  max-height: 40vh;
}

.SubmitP6 input{
  width: 180px;
  padding: 10px;
  margin: 2px;
  background-color: rgba(8,46,108,0.7);
  border: 1px solid rgba(8,46,108,0.85);
  color: #faf7f7;
  font-weight: bold;
  /* font: 0.9em sans-serif; */
  border-radius: 5px;
  margin-right: calc(50% - 90px);
  cursor: pointer;
}

.en > .boxForm > .boxFormInside > form > .SubmitP6 > input {
  margin-right: 0;
  margin-left: calc(50% - 90px);
  font: 0.9em sans-serif;
}

.SubmitP6 input:active{
  background-color: rgba(8,46,108, 0.9);
  border: 1px solid black;
  box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.2);
}
/* 
.copyRightIcons8{
  position: absolute;
  color: #8d8989;
  bottom: 10px;
  left: 10px;
}
.copyRightIcons8 a{
  color: #8d8989;
  font-style:normal;
  text-decoration:none;
} */

@media screen and (max-width: 720px){
  .smallBoxCallMe {
    width: 300px;
    transition: all 1s;
  }
  .boxPage6{
    width: 70vw; 
    transition: all 1s;
  }
}
@media screen and (min-width: 720px){
  .smallBoxCallMe {transition: all 1s;}
  .boxPage6{transition: all 1s;}
}

@media screen and (max-width: 450px){
  .smallBoxCallMe {
    width: 250px;
    transition: all 1s;
  }
}
@media screen and (min-width: 450px){
  .smallBoxCallMe {transition: all 1s;}
}